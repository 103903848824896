import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const knownProvidersService = {

    findLocation_knownProviders(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? encodeURIComponent(payload.search.trim()) : '' },
            { query: 'status', val: payload.status },
            { query: 'dhcidn', val: payload.dhcidn ? encodeURIComponent(payload.dhcidn) : '' },
            { query: 'accountName', val: payload.accountName ? encodeURIComponent(payload.accountName) : ''},
        ]
        return http.get(`${API_ROOT}/notisphere/knownProviders${param(arr)}`).then(resp => {
            return resp
        })
    },

    knownPro_getSearchMeta(Id) {
        return http.get(`${API_ROOT}/notisphere/knownProviders/search-meta`).then(resp => {
            return resp
        })
    },

    create_KnownProvider(payload) {
        return http.post(`${API_ROOT}/notisphere/knownProviders`, payload).then(resp => {
            return resp
        })
    },

    update_KnownProvider(payload) {
        return http.put(`${API_ROOT}/notisphere/knownProviders/${payload.id}`, payload).then(resp => {
            return resp
        })
    },

    getknownProvider(payload) {
        return http.get(`${API_ROOT}/notisphere/knownProviders/pro/${payload.id}`).then(resp => {
            return resp
        })
    },

    deleteKnownProvider(providerId, Status) {
        return http.put(`${API_ROOT}/notisphere/knownProviders/delete/${providerId}/${Status}`).then(resp => {
            return resp
        })
    },

}

export default knownProvidersService